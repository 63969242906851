import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo";
import { Flex, Box } from "@rebass/grid";
import Title from "../components/page-title";
//import Overview from "../components/page-overview";
import Copy from "../components/page-copy";
import Row from "../components/page-content-row";
import PageRangeGrid from "../components/page-range-grid";
import PageButtons from "../components/page-buttons";
import PageCards from "../components/page-cards";
import Image1 from '../images/designs/happyhaus-2Q-form-bold.jpg'
import Image2 from '../images/designs/happyhaus-2D-form-light.jpg'
import Image3 from '../images/designs/happyhaus-2U-form-light.jpg'
import Image4 from '../images/designs/happyhaus-2N-form-light.jpg'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,

]

class Page extends Component {
  render() {
    const { data } = this.props;
    const homesData = data.allHomesJson.edges;

    return (
      <>
        <SEO title="Double Storey Homes" description="Double-storey homes combining 10 years of innovation into our latest collection, designed to suit block widths 10m, 12.5m and 14m." images={ogImages} />
        <Row>
          <Flex>
            <Box width={1} px={2} mr={[0, "20%"]}>
              <Title align="left">
                <p>Double-storey homes combining 10 years of innovation into our latest collection, designed to suit block widths 10m, 12.5m and 14m.</p>
              </Title>
              <Copy>
                <p>
                  <Link to="/designs-single">Single Storey</Link> &nbsp; <Link to="/designs-double">Double Storey</Link>
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>
        <Row>
          <PageRangeGrid range={homesData} storey="double" />
        </Row>
        <Row>
          <Flex>
            <Box width={1} px={2}>
              <PageButtons data={[{ label: "See our Single Storey Homes", link: "/designs-single" }]} />
            </Box>
          </Flex>
        </Row>
        <PageCards id="more" />
      </>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    allHomesJson(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          storey
          title
          stats {
            bed
            bath
            car
            price
            size
          }
          summary
          thumb
        }
      }
    }
  }
`;
