import React, { Component } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import Row from '../components/page-content-row';
import CtaBox from './page-cta-box';

class PageCards extends Component {

  render() {
    const { id } = this.props
		return (
      <Row id={id}>
        <Wrap>
          <Flex flexWrap="nowrap">
            <Box width={1} px={2}>
              <Inner>
                <CtaBox copy="Builder<br />Checklist" ctaText="Download" ctaLink="/builder-checklist" />
                <CtaBox copy="Custom<br />Happy Haus" ctaText="Gallery" ctaLink="/gallery" />
                <CtaBox copy="Luxury<br />Inclusions" ctaText="View" ctaLink="/inclusions" />
                <CtaBox copy="Design<br />Collection" ctaText="View" ctaLink="/designs" />
              </Inner>
            </Box>
          </Flex>
        </Wrap>
      </Row>
		);
	}
}

export default PageCards;

const Wrap = styled.div`
	margin-bottom: 85px;
`

const Inner = styled.div`
	display: flex;
  flex-wrap: nowrap;
  @media (max-width: 800px) {
    flex-wrap: wrap;
	}
`
